.wrapper{
  margin: 10px 10px;
  border:1px solid blue
}


.radio-toolbar {
  margin:22px -3px;
}

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  background-color: #ddd;
  /* padding: 4px 5px; */
  text-align: left;
  font-family: sans-serif, Arial;
  font-size: 14px;
  border: 2px solid #444;
}

.approve{
  padding:4px  6px !important;
  width: 95px;
}

.reject{
  width: 80px;
  padding:4px  2px !important;
}

.radio-toolbar label:first-of-type {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.radio-toolbar label:nth-of-type(2) {
  position: relative;
  left: -6px;
}

.radio-toolbar label:last-of-type {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  /* left: -5px; */
}


i.fas {
  margin-right: 2px;
}

.fa-check-circle{
  text-align: left;
  /* border: 1px solid red; */
}

.radio-toolbar label.approve:hover {
  background-color: lightgreen; /* change this to the specific color you want when hovering over the approve button */
}

.radio-toolbar label.reject:hover {
  background-color: #ff7f7f; /* light red color for hovering over the reject button */
}

/* rest of your CSS... */

.radio-toolbar input[type="radio"]:checked + label.approve {
  background-color: green; /* deep green color when approve button is selected */
  color: white; /* text color when approve button is selected */
  font-weight: bold; /* font weight when approve button is selected */
}

.radio-toolbar input[type="radio"]:checked + label.reject {
  background-color: red; /* red color when reject button is selected */
  color: white; /* text color when reject button is selected */
  font-weight: bold; /* font weight when reject button is selected */
}

/* rest of your CSS... */


/* button{
  padding: 6px 20px;
  border-radius: 5px;
  transition: all 100ms ease-in-out;
}
button:hover{
  background-color: #dfd;
  font-size: 1rem;
  font-weight: bold;
} */

#changesCount{
  font-size: .7rem;
  color: #474646;
}
