.add_color{
    /* border: 1px solid yellow; */
    /* background-color: green; */
  }
  
  .batch{
    position: absolute;
    background-color: green;
    color: black;
    left: 2rem;
    top: 0;
    margin-top: 3rem;
  }
  
  .hide_batch{
    display: none;
  }
  
  .product_count{
    color: blue;
    border-radius: 50% 50%;
    background-color: orange;
   padding: .1rem .3rem;
  
  
  }
  .cartCounter{
    position: absolute;
    top: 0;
    margin-top: -.6rem;
    margin-left: .8rem;
    font-weight: bold;
    cursor: pointer;

  }
  .employee_order_btn{
    padding:5px 4px ;
    color: white;
    border-radius: 10px ;
  }
.search-cart-div{
  display: flex;
  justify-content: flex-start;
  gap:12px;
  align-items: center;
}
  .counter_bg_color{
    background-color: red;
  }

  .default_counter_bg_color{
    background-color: black;
  }


  .bd_green{
    border:1px solid green
  }

  input:focus{
    outline: none !important;
  }





.add-button:hover{
  background-color: lightgreen !important;
  color: black !important;
  font-weight: bold;
}



.order_product{
  width:130px;
  padding-left: 10px;
}

.product_list_slider{
  width: 100%;
  /* margin-left: 12rem; */
  /* position: absolute; */

  display: flex;
  justify-content: center;
  /* margin: auto auto; */
  /* width:1000px */
}
.emp_col_width{
  width:30% !important;
}
.emp_action{
  display:flex !important;
  justify-content: flex-end !important;
  width:180px !important;
  padding-right: 4px !important;
}
/* tr{
  border:1px solid yellow
}

td{
  border:1px solid red ;
  width:200px
} */