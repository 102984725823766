@import url('https://fonts.googleapis.com/css?family=Comfortaa:200,300,400,500,600,700,800,900&display=swap');

* {
  margin: 0;
  font-family: 'Comfortaa';
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: black;
}
@keyframes bounceInUp {
  0%,60%,75%,90%,to {
      -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
  }

  0% {
      opacity: 0;
      -webkit-transform: translate3d(0,3000px,0) scaleY(5);
      transform: translate3d(0,3000px,0) scaleY(5)
  }

  60% {
      opacity: 1;
      -webkit-transform: translate3d(0,-20px,0) scaleY(.9);
      transform: translate3d(0,-20px,0) scaleY(.9)
  }

  75% {
      -webkit-transform: translate3d(0,10px,0) scaleY(.95);
      transform: translate3d(0,10px,0) scaleY(.95)
  }

  90% {
      -webkit-transform: translate3d(0,-5px,0) scaleY(.985);
      transform: translate3d(0,-5px,0) scaleY(.985)    }

  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

.animate__bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
  animation-duration: 1s;
  animation-fill-mode: both;
}
@keyframes bounceInRight {
  0%,60%,75%,90%,to {
      -webkit-animation-timing-function: cubic-bezier(.215,.61,.355,1);
      animation-timing-function: cubic-bezier(.215,.61,.355,1)
  }

  0% {
      opacity: 0;
      -webkit-transform: translate3d(3000px,0,0) scaleX(3);
      transform: translate3d(3000px,0,0) scaleX(3)
  }

  60% {
      opacity: 1;
      -webkit-transform: translate3d(-25px,0,0) scaleX(1);
      transform: translate3d(-25px,0,0) scaleX(1)
  }

  75% {
      -webkit-transform: translate3d(10px,0,0) scaleX(.98);
      transform: translate3d(10px,0,0) scaleX(.98)
  }

  90% {
      -webkit-transform: translate3d(-5px,0,0) scaleX(.995);
      transform: translate3d(-5px,0,0) scaleX(.995)
  }

  to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}

.animate__bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
  animation-duration: 1s;
  animation-fill-mode: both;
}
.validation {
  transform: translate(5rem, -58%);
  color: #fd4444;
  opacity: 0;
  transition: all 0.35s;
}
.form_element input:invalid {
  border-color: #fd4444;
}
.form_element input:invalid ~ .validation {
  opacity: 1;
  transform: translate(0, -50%);
}
.slick-prev:before, .slick-next:before {
  color: #000 !important;
}
.ant-drawer {
  /* z-index: 0 !important; */
  /* bottom: 60px; */
} 
/* 
.ant-modal-footer {
  z-index: 1000 !important;
  overflow: hidden !important;
}
.ant-drawer-mask {
  display: none;
}
.ant-modal {
  z-index: 1000 !important;
  position: relative !important;
  overflow:  hidden !important;
}
.ant-drawer-body {
  overflow: hidden !important;
} */

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.add-to-cart-button {
  background: #161616;
  border: none;
  border-radius: 4px;
  box-shadow: 0 3px 13px -2px rgba(0, 0, 0, .15);
  color: #ffffff;
  display: flex;
  font-family: 'Ubuntu', sans-serif;
  font-size: 10px; /* Updated font-size */
  justify-content: space-around;
  min-width: 125px; /* Updated min-width */
  overflow: hidden;
  outline: none;
  padding: 0.5rem; /* Updated padding */
  position: relative;
  text-transform: uppercase;
  transition: 0.4s ease;
  width: auto;
}

.add-to-cart-button .added-to-cart span {
  position: absolute;
  top: -5px;
  font-size: 10px; /* Updated font-size */
  background-color: #146230;
  color: #ffffff;
  padding: 2px 6px;
  border-radius: 10px;
}

.add-to-cart-button:active {
  box-shadow: 0 0 0 0.2rem rgba(252, 186, 3, .45);
  transform: translateY(4px);
}

.add-to-cart-button:hover {
  cursor: pointer;
}

.add-to-cart-button:hover,
.add-to-cart-button:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 186, 3, .45);
  transform: translateY(-1px);
}

.add-to-cart-button.added {
  background: #2fbf30;
  box-shadow: 0 0 0 0.2rem rgba(11, 252, 3, 0.45);
}

.add-to-cart-button.added .add-to-cart {
  display: none;
}

.add-to-cart-button.added .added-to-cart {
  display: block;
}

.add-to-cart-button.added .cart-icon {
  animation: drop 0.3s forwards;
}

.add-to-cart-button.added .box-1,
.add-to-cart-button.added .box-2 {
  top: 17px;
}

.add-to-cart-button.added .tick {
  animation: grow 0.6s forwards;
}

.add-to-cart,
.added-to-cart {
  margin-left: 36px;
}

.added-to-cart {
  display: none;
  position: relative;
}

.add-to-cart-box {
  height: 5px;
  position: absolute;
  top: 0;
  width: 5px;
}

.box-1,
.box-2 {
  transition: 0.4s ease;
  top: -10px;
}

.box-1 {
  left: 12px;
  transform: rotate(45deg) scale(0.7); /* Added scale */
}

.box-2 {
  left: 17px;
  transform: rotate(63deg) scale(0.7);
}

.cart-icon {
  left: 5px;
  position: absolute;
  top: 8px;
}

.tick {
  background: #146230;
  border-radius: 50%;
  position: absolute;
  left: 22px;
  transform: scale(0);
  top: 3px;
  z-index: 2;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes drop {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(1px);
  }
}

/* Page style */
.container {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100%;
}

/* Budget Request Amount Animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 2s infinite;
}

/*Euro price field
.ant-input-group .ant-input-group-addon {
  background-color: rgba(255, 255, 255, 1) !important;
}

*/
.fixed-width-input {
  width: 120px; /* Or any width according to your layout and requirements */
}
